import React from 'react'
import { ForgotPassword } from 'starterComponents'
import Seo from '../components/seo'

const ResetPasswordPage = (props) => {
  return (
    <div>
      <Seo title="Reset Password - ATSG" />
      <ForgotPassword />
    </div>
  )
}

export default ResetPasswordPage
